.intro {
    margin: 5rem 0 2.5rem 0;
}

.hello {
    font-size: 3em;
}

.link_icon {
    margin-right: 1rem;
    font-size: 2em;
}

.animation {
    margin-bottom: 3em;
    margin-top: 2em;
}

.introAnimation {
    font-size: 2em;
    font-weight: bold;
    margin-top: 2em;
    margin-bottom: 2em;
}