.title_text {
    font-size: 1.4em;
}

.content {
    font-size: 1.4em;
}

.tech {
    display: inline-block;
    padding: 7.5px;
    border-radius: 5px;
    margin: auto 0.5em 1.2em auto;
    text-align: center;
    font-size: 0.75em;
    background-color: #16202d;
}

.links {
    margin-right: 0.5rem;
    font-size: 1.3em;
}

.title:hover {
    transform: scale(1.015, 1.015);
}

.projects {
    margin: 2.5rem 0 2.5rem 0;
}

.experience {
    margin: 2.5rem 0 2.5rem 0;
}

.context {
    font-style: italic;
}



