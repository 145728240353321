.about {
    margin: 2.5rem 0 2.5rem 0;
    font-size: 1.15em;
}

.interests {
    font-size: 1.15em;
}

.interests_grid {
    display: grid;
    grid-row: auto auto;
    align-items: center;
    justify-content: center;
    grid-template-columns: 12em 12em 12em; 
    grid-row-gap: 3em;
}
 
.interest {
    font-size: 1.3em;
    text-align: center; 
}