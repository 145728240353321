* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#dark, 
#dark > div {
  background-color: #16202d;
  color: #cacaca;
}

#dark a, 
#dark .links:hover {
  color: #cacaca;
}

#dark a:hover, 
#dark .links {
  color: #ffffff;
}

#dark .header,
#dark .ui.inverted.segment {
  background-color: #1f2c3e;
}

#light, 
#light > div {
  background-color: #e3e3e3;
  color: #454545;
}

#light a, 
#light .links {
  color: #454545;
}

#light a:hover, 
#light .links:hover {
  color: black;
}

#light .header,
#light .ui.inverted.segment {
  background-color: #dbd9d9;
}

#light .context,
#light .item_content, 
#light .title_text {
  color: #454545
}

#light .tech {
  background-color: #e3e3e3;
  color: #454545;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
}

.home {
    float: left;
    text-align: center;
    margin: 18px;
    font-size: 2em;
    font-weight: bold;
    line-height: 25px;
    color: #cacaca;
}

.home:hover {
    color: #ffffff;
    transform: scale(1.1, 1.1);
}

.link {
    float: left;
    text-align: center;
    margin: 18px;
    font-size: 1.25em;
    line-height: 25px;
    cursor: pointer;
}

.link:hover {
    transform: scale(1.1, 1.1);
}

.switch {
  margin-top: 1.2em;
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.sections {
    margin: auto 20rem auto 20rem;
    font-size: 1.2em;
    word-wrap: normal;
}

h2 {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 1500px) {
    .sections {
      margin-left: 10rem;
      margin-right: 10rem;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .sections {
      margin-left: 8rem;
      margin-right: 8rem;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .sections {
      margin-left: 4rem;
      margin-right: 4rem;
    }
  }